<template>
  <div class="eye" @click="clickAction">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" clip-path="url(#clip0_1033_13376)">
        <path d="M15.9586 7.80948C12.8656 1.42089 3.10665 1.45354 0.0394297 7.80948C-0.0144453 7.9307 -0.0144453 8.06904 0.0394297 8.19026C3.13162 14.5775 12.8881 14.5501 15.9586 8.19026C16.0125 8.06901 16.0125 7.9307 15.9586 7.80948ZM0.993117 7.99989C1.51637 7.03314 2.54081 5.7572 4.08771 4.90879C2.6648 6.62623 2.66506 9.37379 4.08771 11.0909C2.53784 10.2409 1.51452 8.96323 0.993117 7.99989ZM7.99905 12.0499C5.76587 12.0499 3.94905 10.233 3.94905 7.99985C4.17152 2.62698 11.8274 2.62854 12.0491 7.99989C12.0491 10.233 10.2322 12.0499 7.99905 12.0499ZM11.9104 11.0909C13.3333 9.37348 13.3331 6.62592 11.9104 4.90882C13.4603 5.75882 14.4836 7.03651 15.005 7.99985C14.4817 8.96657 13.4573 10.2425 11.9104 11.0909Z" fill="#FF7F27"/>
        <path d="M7.99917 6.0249C6.91014 6.0249 6.02417 6.9109 6.02417 7.99991C6.13267 10.62 9.86607 10.6193 9.97419 7.99991C9.97416 6.91087 9.08819 6.0249 7.99917 6.0249ZM7.99917 9.03741C7.42707 9.03741 6.96167 8.57197 6.96167 7.99991C7.01867 6.6235 8.97988 6.6239 9.03666 7.99991C9.03666 8.572 8.57126 9.03741 7.99917 9.03741Z" fill="#FF7F27"/>
      </g>
      <defs>
        <clipPath id="clip0_1033_13376">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'scrollButton',
  props: {
    onClick: {
      type: Function
    }
  },
  methods: {
    clickAction () {
      this.onClick()
    }
  }
}
</script>

<style lang="scss" src="./eye.scss" scoped />
