import Pusher from 'pusher-js'

const key = 'WI4rDSiX'

export default {
  namespaced: true,
  state () {
    return {
      pusher: null
    }
  },
  mutations: {
    start (state) {
      state.pusher = new Pusher(key, {
        wsHost: 'pusher.' + window.siteName,
        wsPort: 443,
        enabledTransports: ['ws', 'wss'],
        disableStats: true,
        authEndpoint: window.domen + '/api/broadcasting/auth',
        auth: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }
      })
    },
    subscribe (state, props) {
      const { channel, eventName, callback } = props
      const channelInstance = state.pusher.subscribe(channel)
      channelInstance.bind(eventName, callback)
    },
    unsubscribe (state, props) {
      const { channel } = props
      state.pusher.unsubscribe(channel)
    }
  }
}
