<template>
  <publicArea :humans="false">
    <div class="error-content">
      <logo />
      <div class="error-key">
        <h1 class="error-code">404</h1>
        <icon class="error-illustration" name="errorIllustration" />
      </div>
      <p class="error-text">
        Страница, которую вы запрашивали, не найдена.
        <br>
        Возможно, вы ввели неправильный адрес или страница была удалена.
        <br>
        Проверьте правильность введенного адреса.
      </p>
      <xButton @click="$router.push('admin')" size="big">
        Вернуться
      </xButton>
    </div>
  </publicArea>
  <helper />
</template>

<script>
import xButton from '@/components/xButton'
import logo from '@/components/logo'
import publicArea from '@/components/publicArea'
import helper from '@/components/helper'

export default {
  name: 'error',
  components: {
    xButton,
    logo,
    publicArea,
    helper
  }
}
</script>

<style lang="scss" src="./error.scss" scoped />
